import React, { useState } from 'react'
import { Modal, Button, Element, Heading, Section } from "react-bulma-components"

const defaultTitle = ""
const defaultBody = ""
const defaultButtonLabel = "Fermer"

export const useModal = (config) => {

    const [isDisplayed, setIsDisplayed] = useState(config ? config.isDisplayed : true)

    const MyModal = ({ title, body, images, buttonLabel, action, slug, showClose = true }) => (
        <Modal show={isDisplayed} closeOnEsc onClose={() => setIsDisplayed(false)} showClose={showClose}>
            <Modal.Content>
                <Section>
                    <Heading renderAs='h2' size={1}>{title || defaultTitle}</Heading>
                    <Element className='mb-5'>
                        {body || defaultBody}
                    </Element>
                    <Element renderAs='p' className='mb-5'>
                        {images && Array.isArray(images) &&
                            images.map(image =>
                                <img src={'/scenes/' + slug + "/" + image} />
                            )
                        }
                    </Element>
                    <Element renderAs='p' className='mb-5'>
                        <Button
                            color='primary'
                            outlined
                            onClick={() => {
                                setIsDisplayed(false)
                                action && action()
                            }}
                        >{buttonLabel || defaultButtonLabel}</Button>
                    </Element>
                </Section>
            </Modal.Content>
        </Modal>
    )

    return ({ Modal: MyModal, isDisplayed, setIsDisplayed })
}