/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, navigate } from "gatsby"
import { useLocation } from "@reach/router"
import { useTranslation } from "gatsby-plugin-react-i18next"
import "../styles/styles.scss"
import { useModal } from "../hooks/use-modal"
import { Element, Icon } from "react-bulma-components"
import { isIOS } from "../lib/is-ios"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleDot, faCompass } from "@fortawesome/free-solid-svg-icons"

const Layout = ({ children }) => {
  const askPermissionsDialog = useModal({ isDisplayed: true })
  const welcomeDialog = useModal({ isDisplayed: true })
  const [permissionsGranted, setPermissionsGranted] = useState(false)
  const [welcomeDone, setWelcomeDone] = useState(false)
  const { t } = useTranslation(["slugs", "components"])

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  
  // No deviceOrientation at all provided by the browser
  if (typeof DeviceOrientationEvent !== 'function') {
    console.log('DeviceOrientationEvent not detected')
    navigate('/not-supported')
    return <>{''}</>
  }


  console.log({ permissionsGranted, welcomeDone })
  /*   const tSlugs = t('slugs')
   */
  if (!permissionsGranted && isIOS()) {
    if (typeof DeviceOrientationEvent.requestPermission !== 'function') {
      // Seems like this Apple product doesn't support requestPermission
      console.log('DeviceOrientationEvent.requestPermission not detected')
      navigate('/not-supported')
      return <>{''}</>
    }
    return (
      <askPermissionsDialog.Modal
        title={"Activer la boussole"}
        body={<Element>Veuillez autoriser l'accès à la boussole de votre smartphone.<br />Cette application ne fonctionne que sur des smartphones disposant d'une boussole et d'accéléromètres.</Element>}
        buttonLabel={"Autoriser l'accès à la boussole"}
        action={() => {
          DeviceOrientationEvent.requestPermission()
            .then((response) => {
              console.log(response)
              setPermissionsGranted(true)
            })
            .catch(() => alert("not supported"));
        }}
        showClose={false}
      />
    )
  }
  if (!permissionsGranted && !isIOS()) {
    setPermissionsGranted(true)
  }

  if (permissionsGranted && !welcomeDone) {
    return (
      <welcomeDialog.Modal
        title={"Bienvenue"}
        body={
          <Element>
            <ul>
              <Element mb={3}>
                <Icon text>
                  <Icon color={'primary'}><FontAwesomeIcon icon={faCircleDot} /></Icon>
                  Découvrez les points d'intérêt en cliquant sur les sphères
                </Icon>
              </Element>
              <Element>
                <Icon text>
                  <Icon color={'primary'}><FontAwesomeIcon icon={faCompass} /></Icon>
                  Cliquez sur la boussole en cas de problème
                </Icon>
              </Element>
            </ul>
          </Element>
        }
        buttonLabel={"Démarrer"}
        showClose={false}
        action={() => setWelcomeDone(true)}
      />
    )
  }

  return (
    <>
      {/* <Header siteTitle={data.site.siteMetadata.title} /> */}
      <main>{children}</main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
